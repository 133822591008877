import * as TraceKit from 'TraceKit';
export interface ILastReferenceIdManager {
  getLast(): string;
  clearLast(): void;
  setLast(eventId: string): void;
}

export interface ILog {
  trace(message: string): void;
  info(message: string): void;
  warn(message: string): void;
  error(message: string): void;
}

                                                                    

export class DefaultLastReferenceIdManager implements ILastReferenceIdManager {
  /**
   * Gets the last event's reference id that was submitted to the server.
   * @type {string}
   * @private
   */
  private _lastReferenceId: string = null;

  /**
   * Gets the last event's reference id that was submitted to the server.
   * @returns {string}
   */
  public getLast(): string {
    return this._lastReferenceId;
  }

  /**
   * Clears the last event's reference id.
   */
  public clearLast(): void {
    this._lastReferenceId = null;
  }

  /**
   * Sets the last event's reference id.
   * @param eventId
   */
  public setLast(eventId: string): void {
    this._lastReferenceId = eventId;
  }
}

                              

export class ConsoleLog implements ILog {
  public trace(message: string): void {
    this.log('debug', message);
  }

  public info(message: string): void {
    this.log('info', message);
  }

  public warn(message: string): void {
    this.log('warn', message);
  }

  public error(message: string): void {
    this.log('error', message);
  }

  private log(level: string, message: string) {
    if (console) {
      const msg = `[${level}] Exceptionless: ${message}`;

      if (console[level]) {
        console[level](msg);
      } else if (console.log) {
        console[`log`](msg);
      }
    }
  }
}

                              

export class NullLog implements ILog {
  public trace(message: string): void { }
  public info(message: string): void { }
  public warn(message: string): void { }
  public error(message: string): void { }
}

export interface IUserInfo {
  identity?: string;
  name?: string;
  data?: any;
}

                                                                                                                                                               

export class HeartbeatPlugin implements IEventPlugin {
  public priority: number = 100;
  public name: string = 'HeartbeatPlugin';

  private _interval: number;
  private _intervalId: any;

  constructor(heartbeatInterval: number = 30000) {
    this._interval = heartbeatInterval >= 30000 ? heartbeatInterval : 60000;
  }

  public run(context: EventPluginContext, next?: () => void): void {
    clearInterval(this._intervalId);

    const user: IUserInfo = context.event.data['@user'];
    if (user && user.identity) {
      this._intervalId = setInterval(() => context.client.submitSessionHeartbeat(user.identity), this._interval);
    }

    next && next();
  }
}

                                                                                                                                                

export class ReferenceIdPlugin implements IEventPlugin {
  public priority: number = 20;
  public name: string = 'ReferenceIdPlugin';

  public run(context: EventPluginContext, next?: () => void): void {
    if ((!context.event.reference_id || context.event.reference_id.length === 0) && context.event.type === 'error') {
      context.event.reference_id = Utils.guid().replace('-', '').substring(0, 10);
    }

    next && next();
  }
}

                                                                                                                                                                                             

export class EventPluginContext {
  public cancelled: boolean;
  public client: ExceptionlessClient;
  public event: IEvent;
  public contextData: ContextData;

  constructor(client: ExceptionlessClient, event: IEvent, contextData?: ContextData) {
    this.client = client;
    this.event = event;
    this.contextData = contextData ? contextData : new ContextData();
  }

  public get log(): ILog {
    return this.client.config.log;
  }
}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         

export class EventPluginManager {
  public static run(context: EventPluginContext, callback: (context?: EventPluginContext) => void): void {
    const wrap = (plugin: IEventPlugin, next?: () => void): () => void => {
      return () => {
        try {
          if (!context.cancelled) {
            plugin.run(context, next);
          }
        } catch (ex) {
          context.cancelled = true;
          context.log.error(`Error running plugin '${plugin.name}': ${ex.message}. Discarding Event.`);
        }

        if (context.cancelled && !!callback) {
          callback(context);
        }
      };
    };

    const plugins: IEventPlugin[] = context.client.config.plugins; // optimization for minifier.
    const wrappedPlugins: Array<() => void> = [];
    if (!!callback) {
      wrappedPlugins[plugins.length] = wrap({ name: 'cb', priority: 9007199254740992, run: callback }, null);
    }

    for (let index = plugins.length - 1; index > -1; index--) {
      wrappedPlugins[index] = wrap(plugins[index], !!callback || (index < plugins.length - 1) ? wrappedPlugins[index + 1] : null);
    }

    wrappedPlugins[0]();
  }

  public static addDefaultPlugins(config: Configuration): void {
    config.addPlugin(new ConfigurationDefaultsPlugin());
    config.addPlugin(new ErrorPlugin());
    config.addPlugin(new DuplicateCheckerPlugin());
    config.addPlugin(new EventExclusionPlugin());
    config.addPlugin(new ModuleInfoPlugin());
    config.addPlugin(new RequestInfoPlugin());
    config.addPlugin(new EnvironmentInfoPlugin());
    config.addPlugin(new SubmissionMethodPlugin());
  }
}

                                                                                                         

export interface IEventPlugin {
  priority?: number;
  name?: string;
  run(context: EventPluginContext, next?: () => void): void;
}

                                                                                                                                                                                                                                                                                                                                     

export class DefaultEventQueue implements IEventQueue {
  /**
   * The configuration object.
   * @type {Configuration}
   * @private
   */
  private _config: Configuration;

  /**
   * A list of handlers that will be fired when events are submitted.
   * @type {Array}
   * @private
   */
  private _handlers: Array<(events: IEvent[], response: SubmissionResponse) => void> = [];

  /**
   * Suspends processing until the specified time.
   * @type {Date}
   * @private
   */
  private _suspendProcessingUntil: Date;

  /**
   * Discards queued items until the specified time.
   * @type {Date}
   * @private
   */
  private _discardQueuedItemsUntil: Date;

  /**
   * Returns true if the queue is processing.
   * @type {boolean}
   * @private
   */
  private _processingQueue: boolean = false;

  /**
   * Processes the queue every xx seconds.
   * @type {Timer}
   * @private
   */
  private _queueTimer: any;

  constructor(config: Configuration) {
    this._config = config;
  }

  public enqueue(event: IEvent): void {
    const eventWillNotBeQueued: string = 'The event will not be queued.'; // optimization for minifier.
    const config: Configuration = this._config; // Optimization for minifier.
    const log: ILog = config.log; // Optimization for minifier.

    if (!config.enabled) {
      log.info(`Configuration is disabled. ${eventWillNotBeQueued}`);
      return;
    }

    if (!config.isValid) {
      log.info(`Invalid Api Key. ${eventWillNotBeQueued}`);
      return;
    }

    if (this.areQueuedItemsDiscarded()) {
      log.info(`Queue items are currently being discarded. ${eventWillNotBeQueued}`);
      return;
    }

    this.ensureQueueTimer();

    const timestamp = config.storage.queue.save(event);
    const logText = `type=${event.type} ${!!event.reference_id ? 'refid=' + event.reference_id : ''}`;
    if (timestamp) {
      log.info(`Enqueuing event: ${timestamp} ${logText}`);
    } else {
      log.error(`Could not enqueue event ${logText}`);
    }
  }

  public process(isAppExiting?: boolean): void {
    const queueNotProcessed: string = 'The queue will not be processed.'; // optimization for minifier.
    const config: Configuration = this._config; // Optimization for minifier.
    const log: ILog = config.log; // Optimization for minifier.

    if (this._processingQueue) {
      return;
    }

    log.info('Processing queue...');
    if (!config.enabled) {
      log.info(`Configuration is disabled. ${queueNotProcessed}`);
      return;
    }

    if (!config.isValid) {
      log.info(`Invalid Api Key. ${queueNotProcessed}`);
      return;
    }

    this._processingQueue = true;
    this.ensureQueueTimer();

    try {
      const events = config.storage.queue.get(config.submissionBatchSize);
      if (!events || events.length === 0) {
        this._processingQueue = false;
        return;
      }

      log.info(`Sending ${events.length} events to ${config.serverUrl}.`);
      config.submissionClient.postEvents(events.map((e) => e.value), config, (response: SubmissionResponse) => {
        this.processSubmissionResponse(response, events);
        this.eventsPosted(events.map((e) => e.value), response);
        log.info('Finished processing queue.');
        this._processingQueue = false;
      }, isAppExiting);
    } catch (ex) {
      log.error(`Error processing queue: ${ex}`);
      this.suspendProcessing();
      this._processingQueue = false;
    }
  }

  public suspendProcessing(durationInMinutes?: number, discardFutureQueuedItems?: boolean, clearQueue?: boolean): void {
    const config: Configuration = this._config; // Optimization for minifier.

    if (!durationInMinutes || durationInMinutes <= 0) {
      durationInMinutes = 5;
    }

    config.log.info(`Suspending processing for ${durationInMinutes} minutes.`);
    this._suspendProcessingUntil = new Date(new Date().getTime() + (durationInMinutes * 60000));

    if (discardFutureQueuedItems) {
      this._discardQueuedItemsUntil = this._suspendProcessingUntil;
    }

    if (clearQueue) {
      // Account is over the limit and we want to ensure that the sample size being sent in will contain newer errors.
      config.storage.queue.clear();
    }
  }

  public onEventsPosted(handler: (events: IEvent[], response: SubmissionResponse) => void): void {
    !!handler && this._handlers.push(handler);
  }

  private eventsPosted(events: IEvent[], response: SubmissionResponse) {
    const handlers = this._handlers; // optimization for minifier.
    for (const handler of handlers) {
      try {
        handler(events, response);
      } catch (ex) {
        this._config.log.error(`Error calling onEventsPosted handler: ${ex}`);
      }
    }
  }

  private areQueuedItemsDiscarded(): boolean {
    return this._discardQueuedItemsUntil && this._discardQueuedItemsUntil > new Date();
  }

  private ensureQueueTimer(): void {
    if (!this._queueTimer) {
      this._queueTimer = setInterval(() => this.onProcessQueue(), 10000);
    }
  }

  private isQueueProcessingSuspended(): boolean {
    return this._suspendProcessingUntil && this._suspendProcessingUntil > new Date();
  }

  private onProcessQueue(): void {
    if (!this.isQueueProcessingSuspended() && !this._processingQueue) {
      this.process();
    }
  }

  private processSubmissionResponse(response: SubmissionResponse, events: IStorageItem[]): void {
    const noSubmission: string = 'The event will not be submitted.'; // Optimization for minifier.
    const config: Configuration = this._config; // Optimization for minifier.
    const log: ILog = config.log; // Optimization for minifier.

    if (response.success) {
      log.info(`Sent ${events.length} events.`);
      this.removeEvents(events);
      return;
    }

    if (response.serviceUnavailable) {
      // You are currently over your rate limit or the servers are under stress.
      log.error('Server returned service unavailable.');
      this.suspendProcessing();
      return;
    }

    if (response.paymentRequired) {
      // If the organization over the rate limit then discard the event.
      log.info('Too many events have been submitted, please upgrade your plan.');
      this.suspendProcessing(null, true, true);
      return;
    }

    if (response.unableToAuthenticate) {
      // The api key was suspended or could not be authorized.
      log.info(`Unable to authenticate, please check your configuration. ${noSubmission}`);
      this.suspendProcessing(15);
      this.removeEvents(events);
      return;
    }

    if (response.notFound || response.badRequest) {
      // The service end point could not be found.
      log.error(`Error while trying to submit data: ${response.message}`);
      this.suspendProcessing(60 * 4);
      this.removeEvents(events);
      return;
    }

    if (response.requestEntityTooLarge) {
      const message = 'Event submission discarded for being too large.';
      if (config.submissionBatchSize > 1) {
        log.error(`${message} Retrying with smaller batch size.`);
        config.submissionBatchSize = Math.max(1, Math.round(config.submissionBatchSize / 1.5));
      } else {
        log.error(`${message} ${noSubmission}`);
        this.removeEvents(events);
      }

      return;
    }

    if (!response.success) {
      log.error(`Error submitting events: ${response.message || 'Please check the network tab for more info.'}`);
      this.suspendProcessing();
    }
  }

  private removeEvents(events: IStorageItem[]) {
    for (let index = 0; index < (events || []).length; index++) {
      this._config.storage.queue.remove(events[index].timestamp);
    }
  }
}

                                                                                                                 

export interface IEventQueue {
  enqueue(event: IEvent): void;
  process(isAppExiting?: boolean): void;
  suspendProcessing(durationInMinutes?: number, discardFutureQueuedItems?: boolean, clearQueue?: boolean): void;
  onEventsPosted(handler: (events: IEvent[], response: SubmissionResponse) => void): void;
}

                                                                                                                                  

export interface IEnvironmentInfoCollector {
  getEnvironmentInfo(context: EventPluginContext): IEnvironmentInfo;
}

                                                                                                              

export interface IErrorParser {
  parse(context: EventPluginContext, exception: Error): IError;
}

                                                                                                                

export interface IModuleCollector {
  getModules(context: EventPluginContext): IModule[];
}

                                                                                                                          

export interface IRequestInfoCollector {
  getRequestInfo(context: EventPluginContext): IRequestInfo;
}

                                                                                                                                                  

export class InMemoryStorageProvider implements IStorageProvider {
  public queue: IStorage;
  public settings: IStorage;

  constructor(maxQueueItems: number = 250) {
    this.queue = new InMemoryStorage(maxQueueItems);
    this.settings = new InMemoryStorage(1);
  }

}

                                      

export interface IStorageProvider {
  queue: IStorage;
  settings: IStorage;
}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        

// tslint:disable-next-line:prefer-const
declare var XDomainRequest: { new(); create(); };

export class DefaultSubmissionClient implements ISubmissionClient {
  public configurationVersionHeader: string = 'x-exceptionless-configversion';

  public postEvents(events: IEvent[], config: Configuration, callback: (response: SubmissionResponse) => void, isAppExiting?: boolean): void {
    const data = JSON.stringify(events);
    const request = this.createRequest(config, 'POST', `${config.serverUrl}/api/v2/events`, data);
    const cb = this.createSubmissionCallback(config, callback);

    return config.submissionAdapter.sendRequest(request, cb, isAppExiting);
  }

  public postUserDescription(referenceId: string, description: IUserDescription, config: Configuration, callback: (response: SubmissionResponse) => void): void {
    const path = `${config.serverUrl}/api/v2/events/by-ref/${encodeURIComponent(referenceId)}/user-description`;
    const data = JSON.stringify(description);
    const request = this.createRequest(config, 'POST', path, data);
    const cb = this.createSubmissionCallback(config, callback);

    return config.submissionAdapter.sendRequest(request, cb);
  }

  public getSettings(config: Configuration, version: number, callback: (response: SettingsResponse) => void): void {
    const request = this.createRequest(config, 'GET', `${config.configServerUrl}/api/v2/projects/config?v=${version}`);
    const cb = (status, message, data?, headers?) => {
      if (status !== 200) {
        return callback(new SettingsResponse(false, null, -1, null, message));
      }

      let settings: IClientConfiguration;
      try {
        settings = JSON.parse(data);
      } catch (e) {
        config.log.error(`Unable to parse settings: '${data}'`);
      }

      if (!settings || isNaN(settings.version)) {
        return callback(new SettingsResponse(false, null, -1, null, 'Invalid configuration settings.'));
      }

      callback(new SettingsResponse(true, settings.settings || {}, settings.version));
    };

    return config.submissionAdapter.sendRequest(request, cb);
  }

  public sendHeartbeat(sessionIdOrUserId: string, closeSession: boolean, config: Configuration): void {
    const request = this.createRequest(config, 'GET', `${config.heartbeatServerUrl}/api/v2/events/session/heartbeat?id=${sessionIdOrUserId}&close=${closeSession}`);
    config.submissionAdapter.sendRequest(request);
  }

  private createRequest(config: Configuration, method: string, url: string, data: string = null): SubmissionRequest {
    return {
      method,
      url,
      data,
      apiKey: config.apiKey,
      userAgent: config.userAgent
    };
  }

  private createSubmissionCallback(config: Configuration, callback: (response: SubmissionResponse) => void) {
    return (status, message, data?, headers?) => {
      const settingsVersion: number = headers && parseInt(headers[this.configurationVersionHeader], 10);
      if (!isNaN(settingsVersion)) {
        SettingsManager.checkVersion(settingsVersion, config);
      } else {
        config.log.error('No config version header was returned.');
      }

      callback(new SubmissionResponse(status, message));
    };
  }
}

                                                                                                                   

export interface ISubmissionAdapter {
  sendRequest(request: SubmissionRequest, callback?: SubmissionCallback, isAppExiting?: boolean): void;
}

                                                                                                                                                                                                                                                                                           

export interface ISubmissionClient {
  postEvents(events: IEvent[], config: Configuration, callback: (response: SubmissionResponse) => void, isAppExiting?: boolean): void;
  postUserDescription(referenceId: string, description: IUserDescription, config: Configuration, callback: (response: SubmissionResponse) => void): void;
  getSettings(config: Configuration, version: number, callback: (response: SettingsResponse) => void): void;
  sendHeartbeat(sessionIdOrUserId: string, closeSession: boolean, config: Configuration): void;
}

export class Utils {
  public static addRange<T>(target: T[], ...values: T[]) {
    if (!target) {
      target = [];
    }

    if (!values || values.length === 0) {
      return target;
    }

    for (const value of values) {
      if (value && target.indexOf(value) < 0) {
        target.push(value);
      }
    }

    return target;
  }

  public static getHashCode(source: string): number {
    if (!source || source.length === 0) {
      return 0;
    }

    let hash: number = 0;
    for (let index = 0; index < source.length; index++) {
      const character = source.charCodeAt(index);
      hash = ((hash << 5) - hash) + character;
      hash |= 0;
    }

    return hash;
  }

  public static getCookies(cookies: string, exclusions?: string[]): object {
    const result: object = {};

    const parts: string[] = (cookies || '').split('; ');
    for (const part of parts) {
      const cookie: string[] = part.split('=');
      if (!Utils.isMatch(cookie[0], exclusions)) {
        result[cookie[0]] = cookie[1];
      }
    }

    return !Utils.isEmpty(result) ? result : null;
  }

  public static guid(): string {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  // tslint:disable-next-line:ban-types
  public static merge(defaultValues: Object, values: Object) {
    const result: object = {};

    for (const key in defaultValues || {}) {
      if (defaultValues[key] !== undefined && defaultValues[key] !== null) {
        result[key] = defaultValues[key];
      }
    }

    for (const key in values || {}) {
      if (values[key] !== undefined && values[key] !== null) {
        result[key] = values[key];
      }
    }

    return result;
  }

  public static parseVersion(source: string): string {
    if (!source) {
      return null;
    }

    const versionRegex = /(v?((\d+)\.(\d+)(\.(\d+))?)(?:-([\dA-Za-z\-]+(?:\.[\dA-Za-z\-]+)*))?(?:\+([\dA-Za-z\-]+(?:\.[\dA-Za-z\-]+)*))?)/;
    const matches = versionRegex.exec(source);
    if (matches && matches.length > 0) {
      return matches[0];
    }

    return null;
  }

  public static parseQueryString(query: string, exclusions?: string[]) {
    if (!query || query.length === 0) {
      return null;
    }

    const pairs: string[] = query.split('&');
    if (pairs.length === 0) {
      return null;
    }

    const result: object = {};
    for (const pair of pairs) {
      const parts = pair.split('=');
      if (!Utils.isMatch(parts[0], exclusions)) {
        result[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
      }
    }

    return !Utils.isEmpty(result) ? result : null;
  }

  public static randomNumber(): number {
    return Math.floor(Math.random() * 9007199254740992);
  }

  /**
   * Checks to see if a value matches a pattern.
   * @param input the value to check against the @pattern.
   * @param pattern The pattern to check, supports wild cards (*).
   */
  public static isMatch(input: string, patterns: string[], ignoreCase: boolean = true): boolean {
    if (!input || typeof input !== 'string') {
      return false;
    }

    const trim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
    input = (ignoreCase ? input.toLowerCase() : input).replace(trim, '');

    return (patterns || []).some((pattern) => {
      if (typeof pattern !== 'string') {
        return false;
      }

      pattern = (ignoreCase ? pattern.toLowerCase() : pattern).replace(trim, '');
      if (pattern.length <= 0) {
        return false;
      }

      const startsWithWildcard: boolean = pattern[0] === '*';
      if (startsWithWildcard) {
        pattern = pattern.slice(1);
      }

      const endsWithWildcard: boolean = pattern[pattern.length - 1] === '*';
      if (endsWithWildcard) {
        pattern = pattern.substring(0, pattern.length - 1);
      }

      if (startsWithWildcard && endsWithWildcard) {
        return pattern.length <= input.length && input.indexOf(pattern, 0) !== -1;
      }

      if (startsWithWildcard) {
        return Utils.endsWith(input, pattern);
      }

      if (endsWithWildcard) {
        return Utils.startsWith(input, pattern);
      }

      return input === pattern;
    });
  }

  public static isEmpty(input: object) {
    return input === null || (typeof (input) === 'object' && Object.keys(input).length === 0);
  }

  public static startsWith(input: string, prefix: string): boolean {
    return input.substring(0, prefix.length) === prefix;
  }

  public static endsWith(input: string, suffix: string): boolean {
    return input.indexOf(suffix, input.length - suffix.length) !== -1;
  }

  /**
   * Stringifys an object with optional exclusions and max depth.
   * @param data The data object to add.
   * @param exclusions Any property names that should be excluded.
   * @param maxDepth The max depth of the object to include.
   */
  public static stringify(data: any, exclusions?: string[], maxDepth?: number): string {
    function stringifyImpl(obj: any, excludedKeys: string[]): string {
      const cache: string[] = [];
      return JSON.stringify(obj, (key: string, value: any) => {
        if (Utils.isMatch(key, excludedKeys)) {
          return;
        }

        if (typeof value === 'object' && !!value) {
          if (cache.indexOf(value) !== -1) {
            // Circular reference found, discard key
            return;
          }

          cache.push(value);
        }

        return value;
      });
    }

    if (({}).toString.call(data) === '[object Object]') {
      const flattened = {};
      /* tslint:disable:forin */
      for (const prop in data) {
        const value = data[prop];
        if (value === data) {
          continue;
        }
        flattened[prop] = data[prop];
      }
      /* tslint:enable:forin */

      return stringifyImpl(flattened, exclusions);
    }

    if (({}).toString.call(data) === '[object Array]') {
      const result = [];
      for (let index = 0; index < data.length; index++) {
        result[index] = JSON.parse(stringifyImpl(data[index], exclusions));
      }

      return JSON.stringify(result);
    }

    return stringifyImpl(data, exclusions);
  }

  public static toBoolean(input, defaultValue: boolean = false): boolean {
    if (typeof input === 'boolean') {
      return input;
    }

    if (input === null || typeof input !== 'number' && typeof input !== 'string') {
      return defaultValue;
    }

    switch ((input + '').toLowerCase().trim()) {
      case 'true': case 'yes': case '1': return true;
      case 'false': case 'no': case '0': case null: return false;
    }

    return defaultValue;
  }
}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            

export interface IConfigurationSettings {
  apiKey?: string;
  serverUrl?: string;
  configServerUrl?: string;
  heartbeatServerUrl?: string;
  updateSettingsWhenIdleInterval?: number;
  includePrivateInformation?: boolean;
  environmentInfoCollector?: IEnvironmentInfoCollector;
  errorParser?: IErrorParser;
  lastReferenceIdManager?: ILastReferenceIdManager;
  log?: ILog;
  moduleCollector?: IModuleCollector;
  requestInfoCollector?: IRequestInfoCollector;
  submissionBatchSize?: number;
  submissionClient?: ISubmissionClient;
  submissionAdapter?: ISubmissionAdapter;
  storage?: IStorageProvider;
  queue?: IEventQueue;
}

                                                                                                                                                     

interface ISettingsWithVersion {
  version: number;
  settings: { [key: string]: string };
}

export class SettingsManager {
  private static _isUpdatingSettings: boolean = false;

  /**
   * A list of handlers that will be fired when the settings change.
   * @type {Array}
   * @private
   */
  private static _handlers: Array<(config: Configuration) => void> = [];

  public static onChanged(handler: (config: Configuration) => void) {
    !!handler && this._handlers.push(handler);
  }

  public static applySavedServerSettings(config: Configuration): void {
    if (!config || !config.isValid) {
      return;
    }

    const savedSettings = this.getSavedServerSettings(config);
    config.log.info(`Applying saved settings: v${savedSettings.version}`);
    config.settings = Utils.merge(config.settings, savedSettings.settings);
    this.changed(config);
  }

  public static getVersion(config: Configuration): number {
    if (!config || !config.isValid) {
      return 0;
    }

    const savedSettings = this.getSavedServerSettings(config);
    return savedSettings.version || 0;
  }

  public static checkVersion(version: number, config: Configuration): void {
    const currentVersion: number = this.getVersion(config);
    if (version <= currentVersion) {
      return;
    }

    config.log.info(`Updating settings from v${currentVersion} to v${version}`);
    this.updateSettings(config, currentVersion);
  }

  public static updateSettings(config: Configuration, version?: number): void {
    if (!config || !config.enabled || this._isUpdatingSettings) {
      return;
    }

    const unableToUpdateMessage = 'Unable to update settings';
    if (!config.isValid) {
      config.log.error(`${unableToUpdateMessage}: ApiKey is not set.`);
      return;
    }

    if (!version || version < 0) {
      version = this.getVersion(config);
    }

    config.log.info(`Checking for updated settings from: v${version}.`);
    this._isUpdatingSettings = true;
    config.submissionClient.getSettings(config, version, (response: SettingsResponse) => {
      try {
        if (!config || !response || !response.success || !response.settings) {
          config.log.warn(`${unableToUpdateMessage}: ${response.message}`);
          return;
        }

        config.settings = Utils.merge(config.settings, response.settings);

        // TODO: Store snapshot of settings after reading from config and attributes and use that to revert to defaults.
        // Remove any existing server settings that are not in the new server settings.
        const savedServerSettings = SettingsManager.getSavedServerSettings(config);
        for (const key in savedServerSettings) {
          if (response.settings[key]) {
            continue;
          }

          delete config.settings[key];
        }

        const newSettings: ISettingsWithVersion = {
          version: response.settingsVersion,
          settings: response.settings
        };

        config.storage.settings.save(newSettings);

        config.log.info(`Updated settings: v${newSettings.version}`);
        this.changed(config);
      } finally {
        this._isUpdatingSettings = false;
      }
    });
  }

  private static changed(config: Configuration) {
    const handlers = this._handlers; // optimization for minifier.
    for (const handler of handlers) {
      try {
        handler(config);
      } catch (ex) {
        config.log.error(`Error calling onChanged handler: ${ex}`);
      }
    }
  }

  private static getSavedServerSettings(config: Configuration): ISettingsWithVersion {
    const item = config.storage.settings.get()[0];
    if (item && item.value && item.value.version && item.value.settings) {
      return item.value;
    }

    return { version: 0, settings: {} };
  }
}

export interface IEvent {
  type?: string;
  source?: string;
  date?: Date;
  tags?: string[];
  message?: string;
  geo?: string;
  value?: number;
  data?: any;
  reference_id?: string;
  count?: number;
}

export class SubmissionResponse {
  public success: boolean = false;
  public badRequest: boolean = false;
  public serviceUnavailable: boolean = false;
  public paymentRequired: boolean = false;
  public unableToAuthenticate: boolean = false;
  public notFound: boolean = false;
  public requestEntityTooLarge: boolean = false;
  public statusCode: number;
  public message: string;

  constructor(statusCode: number, message?: string) {
    this.statusCode = statusCode;
    this.message = message;

    this.success = statusCode >= 200 && statusCode <= 299;
    this.badRequest = statusCode === 400;
    this.serviceUnavailable = statusCode === 503;
    this.paymentRequired = statusCode === 402;
    this.unableToAuthenticate = statusCode === 401 || statusCode === 403;
    this.notFound = statusCode === 404;
    this.requestEntityTooLarge = statusCode === 413;
  }
}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          

export class ExceptionlessClient {
  /**
   * The default ExceptionlessClient instance.
   * @type {ExceptionlessClient}
   * @private
   */
  private static _instance: ExceptionlessClient = null;

  public config: Configuration;

  private _intervalId: any;
  private _timeoutId: any;

  constructor();
  constructor(settings: IConfigurationSettings);
  constructor(apiKey: string, serverUrl?: string);
  constructor(settingsOrApiKey?: IConfigurationSettings | string, serverUrl?: string) {
    this.config = typeof settingsOrApiKey === 'object'
      ? new Configuration(settingsOrApiKey)
      : new Configuration({ apiKey: settingsOrApiKey as string, serverUrl });

    this.updateSettingsTimer(5000);
    this.config.onChanged((config) => this.updateSettingsTimer(this._timeoutId > 0 ? 5000 : 0));
    this.config.queue.onEventsPosted((events, response) => this.updateSettingsTimer());
  }

  public createException(exception: Error): EventBuilder {
    const pluginContextData = new ContextData();
    pluginContextData.setException(exception);
    return this.createEvent(pluginContextData).setType('error');
  }

  public submitException(exception: Error, callback?: (context: EventPluginContext) => void): void {
    this.createException(exception).submit(callback);
  }

  public createUnhandledException(exception: Error, submissionMethod?: string): EventBuilder {
    const builder = this.createException(exception);
    builder.pluginContextData.markAsUnhandledError();
    builder.pluginContextData.setSubmissionMethod(submissionMethod);

    return builder;
  }

  public submitUnhandledException(exception: Error, submissionMethod?: string, callback?: (context: EventPluginContext) => void) {
    this.createUnhandledException(exception, submissionMethod).submit(callback);
  }

  public createFeatureUsage(feature: string): EventBuilder {
    return this.createEvent().setType('usage').setSource(feature);
  }

  public submitFeatureUsage(feature: string, callback?: (context: EventPluginContext) => void): void {
    this.createFeatureUsage(feature).submit(callback);
  }

  public createLog(message: string): EventBuilder;
  public createLog(source: string, message: string): EventBuilder;
  public createLog(source: string, message: string, level: string): EventBuilder;
  public createLog(sourceOrMessage: string, message?: string, level?: string): EventBuilder {
    let builder = this.createEvent().setType('log');

    if (level) {
      builder = builder.setSource(sourceOrMessage).setMessage(message).setProperty('@level', level);
    } else if (message) {
      builder = builder.setSource(sourceOrMessage).setMessage(message);
    } else {
      builder = builder.setMessage(sourceOrMessage);

      try {
        // TODO: Look into using https: //www.stevefenton.co.uk/Content/Blog/Date/201304/Blog/Obtaining-A-Class-Name-At-Runtime-In-TypeScript/
        const caller: any = this.createLog.caller;
        builder = builder.setSource(caller && caller.caller && caller.caller.name);
      } catch (e) {
        this.config.log.trace('Unable to resolve log source: ' + e.message);
      }
    }

    return builder;
  }

  public submitLog(message: string): void;
  public submitLog(source: string, message: string): void;
  public submitLog(source: string, message: string, level: string, callback?: (context: EventPluginContext) => void): void;
  public submitLog(sourceOrMessage: string, message?: string, level?: string, callback?: (context: EventPluginContext) => void): void {
    this.createLog(sourceOrMessage, message, level).submit(callback);
  }

  public createNotFound(resource: string): EventBuilder {
    return this.createEvent().setType('404').setSource(resource);
  }

  public submitNotFound(resource: string, callback?: (context: EventPluginContext) => void): void {
    this.createNotFound(resource).submit(callback);
  }

  public createSessionStart(): EventBuilder {
    return this.createEvent().setType('session');
  }

  public submitSessionStart(callback?: (context: EventPluginContext) => void): void {
    this.createSessionStart().submit(callback);
  }

  public submitSessionEnd(sessionIdOrUserId: string): void {
    if (sessionIdOrUserId) {
      this.config.log.info(`Submitting session end: ${sessionIdOrUserId}`);
      this.config.submissionClient.sendHeartbeat(sessionIdOrUserId, true, this.config);
    }
  }

  public submitSessionHeartbeat(sessionIdOrUserId: string): void {
    if (sessionIdOrUserId) {
      this.config.log.info(`Submitting session heartbeat: ${sessionIdOrUserId}`);
      this.config.submissionClient.sendHeartbeat(sessionIdOrUserId, false, this.config);
    }
  }

  public createEvent(pluginContextData?: ContextData): EventBuilder {
    return new EventBuilder({ date: new Date() }, this, pluginContextData);
  }

  /**
   * Submits the event to be sent to the server.
   * @param event The event data.
   * @param pluginContextData Any contextual data objects to be used by Exceptionless plugins to gather default information for inclusion in the report information.
   * @param callback
   */
  public submitEvent(event: IEvent, pluginContextData?: ContextData, callback?: (context: EventPluginContext) => void): void {
    function cancelled(eventPluginContext: EventPluginContext) {
      if (!!eventPluginContext) {
        eventPluginContext.cancelled = true;
      }

      return !!callback && callback(eventPluginContext);
    }

    const context = new EventPluginContext(this, event, pluginContextData);
    if (!event) {
      return cancelled(context);
    }

    if (!this.config.enabled) {
      this.config.log.info('Event submission is currently disabled.');
      return cancelled(context);
    }

    if (!event.data) {
      event.data = {};
    }

    if (!event.tags || !event.tags.length) {
      event.tags = [];
    }

    EventPluginManager.run(context, (ctx: EventPluginContext) => {
      const config = ctx.client.config;
      const ev = ctx.event;

      if (!ctx.cancelled) {
        // ensure all required data
        if (!ev.type || ev.type.length === 0) {
          ev.type = 'log';
        }

        if (!ev.date) {
          ev.date = new Date();
        }

        config.queue.enqueue(ev);

        if (ev.reference_id && ev.reference_id.length > 0) {
          ctx.log.info(`Setting last reference id '${ev.reference_id}'`);
          config.lastReferenceIdManager.setLast(ev.reference_id);
        }
      }

      !!callback && callback(ctx);
    });
  }

  /**
   * Updates the user's email address and description of an event for the specified reference id.
   * @param referenceId The reference id of the event to update.
   * @param email The user's email address to set on the event.
   * @param description The user's description of the event.
   * @param callback The submission response.
   */
  public updateUserEmailAndDescription(referenceId: string, email: string, description: string, callback?: (response: SubmissionResponse) => void) {
    if (!referenceId || !email || !description || !this.config.enabled) {
      return !!callback && callback(new SubmissionResponse(500, 'cancelled'));
    }

    const userDescription: IUserDescription = { email_address: email, description };
    this.config.submissionClient.postUserDescription(referenceId, userDescription, this.config, (response: SubmissionResponse) => {
      if (!response.success) {
        this.config.log.error(`Failed to submit user email and description for event '${referenceId}': ${response.statusCode} ${response.message}`);
      }

      !!callback && callback(response);
    });
  }

  /**
   * Gets the last event client id that was submitted to the server.
   * @returns {string} The event client id.
   */
  public getLastReferenceId(): string {
    return this.config.lastReferenceIdManager.getLast();
  }

  private updateSettingsTimer(initialDelay?: number) {
    this.config.log.info(`Updating settings timer with delay: ${initialDelay}`);

    this._timeoutId = clearTimeout(this._timeoutId);
    this._timeoutId = clearInterval(this._intervalId);

    const interval = this.config.updateSettingsWhenIdleInterval;
    if (interval > 0) {
      const updateSettings = () => SettingsManager.updateSettings(this.config);
      if (initialDelay > 0) {
        this._timeoutId = setTimeout(updateSettings, initialDelay);
      }

      this._intervalId = setInterval(updateSettings, interval);
    }
  }

  /**
   * The default ExceptionlessClient instance.
   * @type {ExceptionlessClient}
   */
  public static get default() {
    if (ExceptionlessClient._instance === null) {
      ExceptionlessClient._instance = new ExceptionlessClient(null);
    }

    return ExceptionlessClient._instance;
  }
}

export class ContextData {
  public setException(exception: Error): void {
    if (exception) {
      this['@@_Exception'] = exception;
    }
  }

  public get hasException(): boolean {
    return !!this['@@_Exception'];
  }

  public getException(): Error {
    return this['@@_Exception'] || null;
  }

  public markAsUnhandledError(): void {
    this['@@_IsUnhandledError'] = true;
  }

  public get isUnhandledError(): boolean {
    return !!this['@@_IsUnhandledError'];
  }

  public setSubmissionMethod(method: string): void {
    if (method) {
      this['@@_SubmissionMethod'] = method;
    }
  }

  public getSubmissionMethod(): string {
    return this['@@_SubmissionMethod'] || null;
  }
}

export interface IEnvironmentInfo {
  processor_count?: number;
  total_physical_memory?: number;
  available_physical_memory?: number;
  command_line?: string;
  process_name?: string;
  process_id?: string;
  process_memory_size?: number;
  thread_id?: string;
  architecture?: string;
  o_s_name?: string;
  o_s_version?: string;
  ip_address?: string;
  machine_name?: string;
  install_id?: string;
  runtime_version?: string;
  data?: any;
}

export interface IParameter {
  data?: any;
  generic_arguments?: string[];

  name?: string;
  type?: string;
  type_namespace?: string;
}

                                          

export interface IMethod {
  data?: any;
  generic_arguments?: string[];
  parameters?: IParameter[];

  is_signature_target?: boolean;
  declaring_namespace?: string;
  declaring_type?: string;
  name?: string;
  module_id?: number;
}

                                    

export interface IStackFrame extends IMethod {
  file_name?: string;
  line_number?: number;
  column?: number;
}

                                                                                 

export interface IInnerError {
  message?: string;
  type?: string;
  code?: string;
  data?: any;
  inner?: IInnerError;
  stack_trace?: IStackFrame[];
  target_method?: IMethod;
}

export interface IModule {
  data?: any;

  module_id?: number;
  name?: string;
  version?: string;
  is_entry?: boolean;
  created_date?: Date;
  modified_date?: Date;
}

                                                                                 

export interface IError extends IInnerError {
  modules?: IModule[];
}

export interface IRequestInfo {
  user_agent?: string;
  http_method?: string;
  is_secure?: boolean;
  host?: string;
  port?: number;
  path?: string;
  referrer?: string;
  client_ip_address?: string;
  cookies?: any;
  post_data?: any;
  query_string?: any;
  data?: any;
}

export interface IStorageItem {
  timestamp: number;
  value: any;
}

                                              

export interface IStorage {
  save(value: any): number;
  get(limit?: number): IStorageItem[];
  remove(timestamp: number): void;
  clear(): void;
}

export type SubmissionCallback = (status: number, message: string, data?: string, headers?: object) => void;

export interface SubmissionRequest {
  apiKey: string;
  userAgent: string;
  method: string;
  url: string;
  data: string;
}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 

export class Configuration implements IConfigurationSettings {
  /**
   * The default configuration settings that are applied to new configuration instances.
   * @type {IConfigurationSettings}
   * @private
   */
  private static _defaultSettings: IConfigurationSettings = null;

  /**
   * A default list of tags that will automatically be added to every
   * report submitted to the server.
   *
   * @type {Array}
   */
  public defaultTags: string[] = [];

  /**
   * A default list of of extended data objects that will automatically
   * be added to every report submitted to the server.
   *
   * @type {{}}
   */
  public defaultData: object = {};

  /**
   * Whether the client is currently enabled or not. If it is disabled,
   * submitted errors will be discarded and no data will be sent to the server.
   *
   * @returns {boolean}
   */
  public enabled: boolean = true;

  public environmentInfoCollector: IEnvironmentInfoCollector;
  public errorParser: IErrorParser;
  public lastReferenceIdManager: ILastReferenceIdManager = new DefaultLastReferenceIdManager();
  public log: ILog;
  public moduleCollector: IModuleCollector;
  public requestInfoCollector: IRequestInfoCollector;

  /**
   * Maximum number of events that should be sent to the server together in a batch. (Defaults to 50)
   */
  public submissionBatchSize: number;
  public submissionAdapter: ISubmissionAdapter;
  public submissionClient: ISubmissionClient;

  /**
   * Contains a dictionary of custom settings that can be used to control
   * the client and will be automatically updated from the server.
   */
  public settings: object = {};

  public storage: IStorageProvider;

  public queue: IEventQueue;

  /**
   * The API key that will be used when sending events to the server.
   * @type {string}
   * @private
   */
  private _apiKey: string;

  /**
   * The server url that all events will be sent to.
   * @type {string}
   * @private
   */
  private _serverUrl: string = 'https://collector.exceptionless.io';

  /**
   * The config server url that all configuration will be retrieved from.
   * @type {string}
   * @private
   */
  private _configServerUrl: string = 'https://config.exceptionless.io';

  /**
   * The heartbeat server url that all heartbeats will be sent to.
   * @type {string}
   * @private
   */
  private _heartbeatServerUrl: string = 'https://heartbeat.exceptionless.io';

  /**
   * How often the client should check for updated server settings when idle. The default is every 2 minutes.
   * @type {number}
   * @private
   */
  private _updateSettingsWhenIdleInterval: number = 120000;

  /**
   * A list of exclusion patterns.
   * @type {Array}
   * @private
   */
  private _dataExclusions: string[] = [];

  private _includePrivateInformation: boolean;
  private _includeUserName: boolean;
  private _includeMachineName: boolean;
  private _includeIpAddress: boolean;
  private _includeCookies: boolean;
  private _includePostData: boolean;
  private _includeQueryString: boolean;

  /**
   * A list of user agent patterns.
   * @type {Array}
   * @private
   */
  private _userAgentBotPatterns: string[] = [];

  /**
   * The list of plugins that will be used in this configuration.
   * @type {Array}
   * @private
   */
  private _plugins: IEventPlugin[] = [];

  /**
   * A list of handlers that will be fired when configuration changes.
   * @type {Array}
   * @private
   */
  private _handlers: Array<(config: Configuration) => void> = [];

  constructor(configSettings?: IConfigurationSettings) {
    function inject(fn: any) {
      return typeof fn === 'function' ? fn(this) : fn;
    }

    configSettings = Utils.merge(Configuration.defaults, configSettings);

    this.log = inject(configSettings.log) || new NullLog();
    this.apiKey = configSettings.apiKey;
    this.serverUrl = configSettings.serverUrl;
    this.configServerUrl = configSettings.configServerUrl;
    this.heartbeatServerUrl = configSettings.heartbeatServerUrl;
    this.updateSettingsWhenIdleInterval = configSettings.updateSettingsWhenIdleInterval;
    this.includePrivateInformation = configSettings.includePrivateInformation;

    this.environmentInfoCollector = inject(configSettings.environmentInfoCollector);
    this.errorParser = inject(configSettings.errorParser);
    this.lastReferenceIdManager = inject(configSettings.lastReferenceIdManager) || new DefaultLastReferenceIdManager();
    this.moduleCollector = inject(configSettings.moduleCollector);
    this.requestInfoCollector = inject(configSettings.requestInfoCollector);
    this.submissionBatchSize = inject(configSettings.submissionBatchSize) || 50;
    this.submissionAdapter = inject(configSettings.submissionAdapter);
    this.submissionClient = inject(configSettings.submissionClient) || new DefaultSubmissionClient();
    this.storage = inject(configSettings.storage) || new InMemoryStorageProvider();
    this.queue = inject(configSettings.queue) || new DefaultEventQueue(this);

    SettingsManager.applySavedServerSettings(this);
    EventPluginManager.addDefaultPlugins(this);
  }

  /**
   * The API key that will be used when sending events to the server.
   * @returns {string}
   */
  public get apiKey(): string {
    return this._apiKey;
  }

  /**
   * The API key that will be used when sending events to the server.
   * @param value
   */
  public set apiKey(value: string) {
    this._apiKey = value || null;
    this.log.info(`apiKey: ${this._apiKey}`);
    this.changed();
  }

  /**
   * Returns true if the apiKey is valid.
   * @returns {boolean}
   */
  public get isValid(): boolean {
    return !!this.apiKey && this.apiKey.length >= 10;
  }

  /**
   * The server url that all events will be sent to.
   * @returns {string}
   */
  public get serverUrl(): string {
    return this._serverUrl;
  }

  /**
   * The server url that all events will be sent to.
   * @param value
   */
  public set serverUrl(value: string) {
    if (!!value) {
      this._serverUrl = value;
      this._configServerUrl = value;
      this._heartbeatServerUrl = value;
      this.log.info(`serverUrl: ${value}`);
      this.changed();
    }
  }

  /**
   * The config server url that all configuration will be retrieved from.
   * @returns {string}
   */
  public get configServerUrl(): string {
    return this._configServerUrl;
  }

  /**
   * The config server url that all configuration will be retrieved from.
   * @param value
   */
  public set configServerUrl(value: string) {
    if (!!value) {
      this._configServerUrl = value;
      this.log.info(`configServerUrl: ${value}`);
      this.changed();
    }
  }

  /**
   * The heartbeat server url that all heartbeats will be sent to.
   * @returns {string}
   */
  public get heartbeatServerUrl(): string {
    return this._heartbeatServerUrl;
  }

  /**
   * The heartbeat server url that all heartbeats will be sent to.
   * @param value
   */
  public set heartbeatServerUrl(value: string) {
    if (!!value) {
      this._heartbeatServerUrl = value;
      this.log.info(`heartbeatServerUrl: ${value}`);
      this.changed();
    }
  }

  /**
   * How often the client should check for updated server settings when idle. The default is every 2 minutes.
   * @returns {number}
   */
  public get updateSettingsWhenIdleInterval(): number {
    return this._updateSettingsWhenIdleInterval;
  }

  /**
   * How often the client should check for updated server settings when idle. The default is every 2 minutes.
   * @param value
   */
  public set updateSettingsWhenIdleInterval(value: number) {
    if (typeof value !== 'number') {
      return;
    }

    if (value <= 0) {
      value = -1;
    } else if (value > 0 && value < 120000) {
      value = 120000;
    }

    this._updateSettingsWhenIdleInterval = value;
    this.log.info(`updateSettingsWhenIdleInterval: ${value}`);
    this.changed();
  }

  /**
   *  A list of exclusion patterns that will automatically remove any data that
   *  matches them from any data submitted to the server.
   *
   *  For example, entering CreditCard will remove any extended data properties,
   *  form fields, cookies and query parameters from the report.
   *
   * @returns {string[]}
   */
  public get dataExclusions(): string[] {
    const exclusions: string = this.settings['@@DataExclusions'];
    return this._dataExclusions.concat(exclusions && exclusions.split(',') || []);
  }

  /**
   * Add items to the list of exclusion patterns that will automatically remove any
   * data that matches them from any data submitted to the server.
   *
   * For example, entering CreditCard will remove any extended data properties, form
   * fields, cookies and query parameters from the report.
   *
   * @param exclusions
   */
  public addDataExclusions(...exclusions: string[]) {
    this._dataExclusions = Utils.addRange<string>(this._dataExclusions, ...exclusions);
  }

  /**
   * Gets a value indicating whether to include private information about the local machine.
   * @returns {boolean}
   */
  public get includePrivateInformation(): boolean {
    return this._includePrivateInformation;
  }

  /**
   * Sets a value indicating whether to include private information about the local machine
   * @param value
   */
  public set includePrivateInformation(value: boolean) {
    const val = value || false;
    this._includePrivateInformation = val;
    this._includeUserName = val;
    this._includeMachineName = val;
    this._includeIpAddress = val;
    this._includeCookies = val;
    this._includePostData = val;
    this._includeQueryString = val;
    this.log.info(`includePrivateInformation: ${val}`);
    this.changed();
  }

  /**
   * Gets a value indicating whether to include User Name.
   * @returns {boolean}
   */
  public get includeUserName(): boolean {
    return this._includeUserName;
  }

  /**
   * Sets a value indicating whether to include User Name.
   * @param value
   */
  public set includeUserName(value: boolean) {
    this._includeUserName = value || false;
    this.changed();
  }

  /**
   * Gets a value indicating whether to include MachineName in MachineInfo.
   * @returns {boolean}
   */
  public get includeMachineName(): boolean {
    return this._includeMachineName;
  }

  /**
   * Sets a value indicating whether to include MachineName in MachineInfo.
   * @param value
   */
  public set includeMachineName(value: boolean) {
    this._includeMachineName = value || false;
    this.changed();
  }

  /**
   * Gets a value indicating whether to include Ip Addresses in MachineInfo and RequestInfo.
   * @returns {boolean}
   */
  public get includeIpAddress(): boolean {
    return this._includeIpAddress;
  }

  /**
   * Sets a value indicating whether to include Ip Addresses in MachineInfo and RequestInfo.
   * @param value
   */
  public set includeIpAddress(value: boolean) {
    this._includeIpAddress = value || false;
    this.changed();
  }

  /**
   * Gets a value indicating whether to include Cookies.
   * NOTE: DataExclusions are applied to all Cookie keys when enabled.
   * @returns {boolean}
   */
  public get includeCookies(): boolean {
    return this._includeCookies;
  }

  /**
   * Sets a value indicating whether to include Cookies.
   * NOTE: DataExclusions are applied to all Cookie keys when enabled.
   * @param value
   */
  public set includeCookies(value: boolean) {
    this._includeCookies = value || false;
    this.changed();
  }

  /**
   * Gets a value indicating whether to include Form/POST Data.
   * NOTE: DataExclusions are only applied to Form data keys when enabled.
   * @returns {boolean}
   */
  public get includePostData(): boolean {
    return this._includePostData;
  }

  /**
   * Sets a value indicating whether to include Form/POST Data.
   * NOTE: DataExclusions are only applied to Form data keys when enabled.
   * @param value
   */
  public set includePostData(value: boolean) {
    this._includePostData = value || false;
    this.changed();
  }

  /**
   * Gets a value indicating whether to include query string information.
   * NOTE: DataExclusions are applied to all Query String keys when enabled.
   * @returns {boolean}
   */
  public get includeQueryString(): boolean {
    return this._includeQueryString;
  }

  /**
   * Sets a value indicating whether to include query string information.
   * NOTE: DataExclusions are applied to all Query String keys when enabled.
   * @param value
   */
  public set includeQueryString(value: boolean) {
    this._includeQueryString = value || false;
    this.changed();
  }

  /**
   * A list of user agent patterns that will cause any event with a matching user agent to not be submitted.
   *
   * For example, entering *Bot* will cause any events that contains a user agent of Bot will not be submitted.
   *
   * @returns {string[]}
   */
  public get userAgentBotPatterns(): string[] {
    const patterns: string = this.settings['@@UserAgentBotPatterns'];
    return this._userAgentBotPatterns.concat(patterns && patterns.split(',') || []);
  }

  /**
   * Add items to the list of user agent patterns that will cause any event with a matching user agent to not be submitted.
   *
   * For example, entering *Bot* will cause any events that contains a user agent of Bot will not be submitted.
   *
   * @param userAgentBotPatterns
   */
  public addUserAgentBotPatterns(...userAgentBotPatterns: string[]) {
    this._userAgentBotPatterns = Utils.addRange<string>(this._userAgentBotPatterns, ...userAgentBotPatterns);
  }

  /**
   * The list of plugins that will be used in this configuration.
   * @returns {IEventPlugin[]}
   */
  public get plugins(): IEventPlugin[] {
    return this._plugins.sort((p1: IEventPlugin, p2: IEventPlugin) => {
      return (p1.priority < p2.priority) ? -1 : (p1.priority > p2.priority) ? 1 : 0;
    });
  }

  /**
   * Register an plugin to be used in this configuration.
   * @param plugin
   */
  public addPlugin(plugin: IEventPlugin): void;

  /**
   * Register an plugin to be used in this configuration.
   * @param name The name used to identify the plugin.
   * @param priority Used to determine plugins priority.
   * @param pluginAction A function that is run.
   */
  public addPlugin(name: string, priority: number, pluginAction: (context: EventPluginContext, next?: () => void) => void): void;
  public addPlugin(pluginOrName: IEventPlugin | string, priority?: number, pluginAction?: (context: EventPluginContext, next?: () => void) => void): void {
    const plugin: IEventPlugin = !!pluginAction ? { name: pluginOrName as string, priority, run: pluginAction } : pluginOrName as IEventPlugin;
    if (!plugin || !plugin.run) {
      this.log.error('Add plugin failed: Run method not defined');
      return;
    }

    if (!plugin.name) {
      plugin.name = Utils.guid();
    }

    if (!plugin.priority) {
      plugin.priority = 0;
    }

    let pluginExists: boolean = false;
    const plugins = this._plugins; // optimization for minifier.
    for (const p of plugins) {
      if (p.name === plugin.name) {
        pluginExists = true;
        break;
      }
    }

    if (!pluginExists) {
      plugins.push(plugin);
    }
  }

  /**
   * Remove the plugin from this configuration.
   * @param plugin
   */
  public removePlugin(plugin: IEventPlugin): void;

  /**
   * Remove an plugin by key from this configuration.
   * @param name
   */
  public removePlugin(pluginOrName: IEventPlugin | string): void {
    const name: string = typeof pluginOrName === 'string' ? pluginOrName : pluginOrName.name;
    if (!name) {
      this.log.error('Remove plugin failed: Plugin name not defined');
      return;
    }

    const plugins = this._plugins; // optimization for minifier.
    for (let index = 0; index < plugins.length; index++) {
      if (plugins[index].name === name) {
        plugins.splice(index, 1);
        break;
      }
    }
  }

  /**
   * Automatically set the application version for events.
   * @param version
   */
  public setVersion(version: string): void {
    if (!!version) {
      this.defaultData['@version'] = version;
    }
  }

  public setUserIdentity(userInfo: IUserInfo): void;
  public setUserIdentity(identity: string): void;
  public setUserIdentity(identity: string, name: string): void;
  public setUserIdentity(userInfoOrIdentity: IUserInfo | string, name?: string): void {
    const USER_KEY: string = '@user'; // optimization for minifier.
    const userInfo: IUserInfo = typeof userInfoOrIdentity !== 'string' ? userInfoOrIdentity : { identity: userInfoOrIdentity, name };

    const shouldRemove: boolean = !userInfo || (!userInfo.identity && !userInfo.name);
    if (shouldRemove) {
      delete this.defaultData[USER_KEY];
    } else {
      this.defaultData[USER_KEY] = userInfo;
    }

    this.log.info(`user identity: ${shouldRemove ? 'null' : userInfo.identity}`);
  }

  /**
   * Used to identify the client that sent the events to the server.
   * @returns {string}
   */
  public get userAgent(): string {
    return 'exceptionless-js/1.0.0.0';
  }

  /**
   * Automatically send a heartbeat to keep the session alive.
   */
  public useSessions(sendHeartbeats: boolean = true, heartbeatInterval: number = 30000): void {
    if (sendHeartbeats) {
      this.addPlugin(new HeartbeatPlugin(heartbeatInterval));
    }
  }

  /**
   * Automatically set a reference id for error events.
   */
  public useReferenceIds(): void {
    this.addPlugin(new ReferenceIdPlugin());
  }

  public useLocalStorage(): void {
    // This method will be injected via the prototype.
  }

  // TODO: Support a min log level.
  public useDebugLogger(): void {
    this.log = new ConsoleLog();
  }

  public onChanged(handler: (config: Configuration) => void) {
    !!handler && this._handlers.push(handler);
  }

  private changed() {
    const handlers = this._handlers; // optimization for minifier.
    for (const handler of handlers) {
      try {
        handler(this);
      } catch (ex) {
        this.log.error(`Error calling onChanged handler: ${ex}`);
      }
    }
  }

  /**
   * The default configuration settings that are applied to new configuration instances.
   * @returns {IConfigurationSettings}
   */
  public static get defaults() {
    if (Configuration._defaultSettings === null) {
      Configuration._defaultSettings = { includePrivateInformation: true };
    }

    return Configuration._defaultSettings;
  }
}

export interface IUserDescription {
  email_address?: string;
  description?: string;
  data?: any;
}

export class SettingsResponse {
  public success: boolean = false;
  public settings: any;
  public settingsVersion: number = -1;
  public message: string;
  public exception: any;

  constructor(success: boolean, settings: any, settingsVersion: number = -1, exception: any = null, message: string = null) {
    this.success = success;
    this.settings = settings;
    this.settingsVersion = settingsVersion;
    this.exception = exception;
    this.message = message;
  }
}

                                                                                                                                                                                                                                                                                                                                                                                   

export class EventBuilder {
  public target: IEvent;
  public client: ExceptionlessClient;
  public pluginContextData: ContextData;

  private _validIdentifierErrorMessage: string = 'must contain between 8 and 100 alphanumeric or \'-\' characters.'; // optimization for minifier.

  constructor(event: IEvent, client: ExceptionlessClient, pluginContextData?: ContextData) {
    this.target = event;
    this.client = client;
    this.pluginContextData = pluginContextData || new ContextData();
  }

  public setType(type: string): EventBuilder {
    if (!!type) {
      this.target.type = type;
    }

    return this;
  }

  public setSource(source: string): EventBuilder {
    if (!!source) {
      this.target.source = source;
    }

    return this;
  }

  public setReferenceId(referenceId: string): EventBuilder {
    if (!this.isValidIdentifier(referenceId)) {
      throw new Error(`ReferenceId ${this._validIdentifierErrorMessage}`);
    }

    this.target.reference_id = referenceId;
    return this;
  }

  /**
   * Allows you to reference a parent event by its ReferenceId property. This allows you to have parent and child relationships.
   * @param name Reference name
   * @param id The reference id that points to a specific event
   * @returns {EventBuilder}
   */
  public setEventReference(name: string, id: string): EventBuilder {
    if (!name) {
      throw new Error('Invalid name');
    }

    if (!id || !this.isValidIdentifier(id)) {
      throw new Error(`Id ${this._validIdentifierErrorMessage}`);
    }

    this.setProperty('@ref:' + name, id);
    return this;
  }

  public setMessage(message: string): EventBuilder {
    if (!!message) {
      this.target.message = message;
    }

    return this;
  }

  public setGeo(latitude: number, longitude: number): EventBuilder {
    if (latitude < -90.0 || latitude > 90.0) {
      throw new Error('Must be a valid latitude value between -90.0 and 90.0.');
    }

    if (longitude < -180.0 || longitude > 180.0) {
      throw new Error('Must be a valid longitude value between -180.0 and 180.0.');
    }

    this.target.geo = `${latitude},${longitude}`;
    return this;
  }

  public setUserIdentity(userInfo: IUserInfo): EventBuilder;
  public setUserIdentity(identity: string): EventBuilder;
  public setUserIdentity(identity: string, name: string): EventBuilder;
  public setUserIdentity(userInfoOrIdentity: IUserInfo | string, name?: string): EventBuilder {
    const userInfo = typeof userInfoOrIdentity !== 'string' ? userInfoOrIdentity : { identity: userInfoOrIdentity, name };
    if (!userInfo || (!userInfo.identity && !userInfo.name)) {
      return this;
    }

    this.setProperty('@user', userInfo);
    return this;
  }

  /**
   * Sets the user's description of the event.
   *
   * @param emailAddress The email address
   * @param description The user's description of the event.
   * @returns {EventBuilder}
   */
  public setUserDescription(emailAddress: string, description: string): EventBuilder {
    if (emailAddress && description) {
      this.setProperty('@user_description', { email_address: emailAddress, description });
    }

    return this;
  }

  /**
   * Changes default stacking behavior by setting manual
   * stacking information.
   * @param signatureData A dictionary of strings to use for stacking.
   * @param title An optional title for the stacking information.
   * @returns {EventBuilder}
   */
  public setManualStackingInfo(signatureData: any, title?: string) {
    if (signatureData) {
      const stack: IManualStackingInfo = { signature_data: signatureData };
      if (title) {
        stack.title = title;
      }

      this.setProperty('@stack', stack);
    }

    return this;
  }

  /**
   * Changes default stacking behavior by setting the stacking key.
   * @param manualStackingKey The manual stacking key.
   * @param title An optional title for the stacking information.
   * @returns {EventBuilder}
   */
  public setManualStackingKey(manualStackingKey: string, title?: string): EventBuilder {
    if (manualStackingKey) {
      const data = { ManualStackingKey: manualStackingKey };
      this.setManualStackingInfo(data, title);
    }

    return this;
  }

  public setValue(value: number): EventBuilder {
    if (!!value) {
      this.target.value = value;
    }

    return this;
  }

  public addTags(...tags: string[]): EventBuilder {
    this.target.tags = Utils.addRange<string>(this.target.tags, ...tags);
    return this;
  }

  /**
   * Adds the object to extended data. Uses @excludedPropertyNames
   * to exclude data from being included in the event.
   * @param name The data object to add.
   * @param value The name of the object to add.
   * @param maxDepth The max depth of the object to include.
   * @param excludedPropertyNames Any property names that should be excluded.
   */
  public setProperty(name: string, value: any, maxDepth?: number, excludedPropertyNames?: string[]): EventBuilder {
    if (!name || (value === undefined || value == null)) {
      return this;
    }

    if (!this.target.data) {
      this.target.data = {};
    }

    const result = JSON.parse(Utils.stringify(value, this.client.config.dataExclusions.concat(excludedPropertyNames || []), maxDepth));
    if (!Utils.isEmpty(result)) {
      this.target.data[name] = result;
    }

    return this;
  }

  public markAsCritical(critical: boolean): EventBuilder {
    if (critical) {
      this.addTags('Critical');
    }

    return this;
  }

  public addRequestInfo(request: object): EventBuilder {
    if (!!request) {
      this.pluginContextData['@request'] = request;
    }

    return this;
  }

  public submit(callback?: (context: EventPluginContext) => void): void {
    this.client.submitEvent(this.target, this.pluginContextData, callback);
  }

  private isValidIdentifier(value: string): boolean {
    if (!value) {
      return true;
    }

    if (value.length < 8 || value.length > 100) {
      return false;
    }

    for (let index = 0; index < value.length; index++) {
      const code = value.charCodeAt(index);
      const isDigit = (code >= 48) && (code <= 57);
      const isLetter = ((code >= 65) && (code <= 90)) || ((code >= 97) && (code <= 122));
      const isMinus = code === 45;

      if (!(isDigit || isLetter) && !isMinus) {
        return false;
      }
    }

    return true;
  }
}

export interface IManualStackingInfo {
  title?: string;
  signature_data?: any;
}

                                                                                                                                                

export class ConfigurationDefaultsPlugin implements IEventPlugin {
  public priority: number = 10;
  public name: string = 'ConfigurationDefaultsPlugin';

  public run(context: EventPluginContext, next?: () => void): void {
    const config = context.client.config;
    const defaultTags: string[] = config.defaultTags || [];
    for (const tag of defaultTags) {
      if (!!tag && context.event.tags.indexOf(tag) < 0) {
        context.event.tags.push(tag);
      }
    }

    // tslint:disable-next-line:ban-types
    const defaultData: Object = config.defaultData || {};
    for (const key in defaultData) {
      if (!!defaultData[key]) {
        const result = JSON.parse(Utils.stringify(defaultData[key], config.dataExclusions));
        if (!Utils.isEmpty(result)) {
          context.event.data[key] = result;
        }
      }
    }

    next && next();
  }
}

                                                                                                                                                                                                        

export class DuplicateCheckerPlugin implements IEventPlugin {
  public priority: number = 1010;
  public name: string = 'DuplicateCheckerPlugin';

  private _mergedEvents: MergedEvent[] = [];
  private _processedHashcodes: TimestampedHash[] = [];
  private _getCurrentTime: () => number;
  private _interval: number;

  constructor(getCurrentTime: () => number = () => Date.now(), interval: number = 30000) {
    this._getCurrentTime = getCurrentTime;
    this._interval = interval;

    setInterval(() => {
      while (this._mergedEvents.length > 0) {
        this._mergedEvents.shift().resubmit();
      }
    }, interval);
  }

  public run(context: EventPluginContext, next?: () => void): void {
    function getHashCode(e: IInnerError): number {
      let hash = 0;
      while (e) {
        if (e.message && e.message.length) {
          hash += (hash * 397) ^ Utils.getHashCode(e.message);
        }
        if (e.stack_trace && e.stack_trace.length) {
          hash += (hash * 397) ^ Utils.getHashCode(JSON.stringify(e.stack_trace));
        }
        e = e.inner;
      }

      return hash;
    }

    const error = context.event.data['@error'];
    const hashCode = getHashCode(error);
    if (hashCode) {
      const count = context.event.count || 1;
      const now = this._getCurrentTime();

      const merged = this._mergedEvents.filter((s) => s.hashCode === hashCode)[0];
      if (merged) {
        merged.incrementCount(count);
        merged.updateDate(context.event.date);
        context.log.info('Ignoring duplicate event with hash: ' + hashCode);
        context.cancelled = true;
      }

      if (!context.cancelled && this._processedHashcodes.some((h) => h.hash === hashCode && h.timestamp >= (now - this._interval))) {
        context.log.trace('Adding event with hash: ' + hashCode);
        this._mergedEvents.push(new MergedEvent(hashCode, context, count));
        context.cancelled = true;
      }

      if (!context.cancelled) {
        context.log.trace('Enqueueing event with hash: ' + hashCode + 'to cache.');
        this._processedHashcodes.push({ hash: hashCode, timestamp: now });

        // Only keep the last 50 recent errors.
        while (this._processedHashcodes.length > 50) {
          this._processedHashcodes.shift();
        }
      }
    }

    next && next();
  }
}

interface TimestampedHash {
  hash: number;
  timestamp: number;
}

class MergedEvent {
  public hashCode: number;
  private _count: number;
  private _context: EventPluginContext;

  constructor(hashCode: number, context: EventPluginContext, count: number) {
    this.hashCode = hashCode;
    this._context = context;
    this._count = count;
  }

  public incrementCount(count: number) {
    this._count += count;
  }

  public resubmit() {
    this._context.event.count = this._count;
    this._context.client.config.queue.enqueue(this._context.event);
  }

  public updateDate(date) {
    if (date > this._context.event.date) {
      this._context.event.date = date;
    }
  }
}

                                                                                                                                                                             

export class EnvironmentInfoPlugin implements IEventPlugin {
  public priority: number = 80;
  public name: string = 'EnvironmentInfoPlugin';

  public run(context: EventPluginContext, next?: () => void): void {
    const ENVIRONMENT_KEY: string = '@environment'; // optimization for minifier.

    const collector = context.client.config.environmentInfoCollector;
    if (!context.event.data[ENVIRONMENT_KEY] && collector) {
      const environmentInfo: IEnvironmentInfo = collector.getEnvironmentInfo(context);
      if (!!environmentInfo) {
        context.event.data[ENVIRONMENT_KEY] = environmentInfo;
      }
    }

    next && next();
  }
}

                                                                                                                                                

export class ErrorPlugin implements IEventPlugin {
  public priority: number = 30;
  public name: string = 'ErrorPlugin';

  public run(context: EventPluginContext, next?: () => void): void {
    const ERROR_KEY: string = '@error'; // optimization for minifier.
    const ignoredProperties: string[] = [
      'arguments',
      'column',
      'columnNumber',
      'description',
      'fileName',
      'message',
      'name',
      'number',
      'line',
      'lineNumber',
      'opera#sourceloc',
      'sourceId',
      'sourceURL',
      'stack',
      'stackArray',
      'stacktrace'
    ];

    const exception = context.contextData.getException();
    if (!!exception) {
      context.event.type = 'error';

      if (!context.event.data[ERROR_KEY]) {
        const config = context.client.config;
        const parser = config.errorParser;
        if (!parser) {
          throw new Error('No error parser was defined.');
        }

        const result = parser.parse(context, exception);
        if (!!result) {
          const additionalData = JSON.parse(Utils.stringify(exception, config.dataExclusions.concat(ignoredProperties)));
          if (!Utils.isEmpty(additionalData)) {
            if (!result.data) {
              result.data = {};
            }
            result.data['@ext'] = additionalData;
          }

          context.event.data[ERROR_KEY] = result;
        }
      }
    }

    next && next();
  }
}

                                                                                                                                                                                                        

export class EventExclusionPlugin implements IEventPlugin {
  public priority: number = 45;
  public name: string = 'EventExclusionPlugin';

  public run(context: EventPluginContext, next?: () => void): void {
    function getLogLevel(level: string): number {
      switch ((level || '').toLowerCase().trim()) {
        case 'trace':
        case 'true':
        case '1':
        case 'yes':
          return 0;
        case 'debug':
          return 1;
        case 'info':
          return 2;
        case 'warn':
          return 3;
        case 'error':
          return 4;
        case 'fatal':
          return 5;
        case 'off':
        case 'false':
        case '0':
        case 'no':
          return 6;
        default:
          return -1;
      }
    }

    function getMinLogLevel(configSettings: object, loggerName: string = '*'): number {
      return getLogLevel(getTypeAndSourceSetting(configSettings, 'log', loggerName, 'Trace') + '');
    }

    // tslint:disable-next-line:ban-types
    function getTypeAndSourceSetting(configSettings: Object = {}, type: string, source: string, defaultValue?: string | boolean): string | boolean {
      if (!type) {
        return defaultValue;
      }

      const isLog = type === 'log';
      const sourcePrefix = `@@${type}:`;

      const value = configSettings[sourcePrefix + source];
      if (value) {
        return !isLog ? Utils.toBoolean(value) : value;
      }

      // check for wildcard match
      for (const key in configSettings) {
        if (Utils.startsWith(key.toLowerCase(), sourcePrefix.toLowerCase()) && Utils.isMatch(source, [key.substring(sourcePrefix.length)])) {
          return !isLog ? Utils.toBoolean(configSettings[key]) : configSettings[key];
        }
      }

      return defaultValue;
    }

    const ev = context.event;
    const log = context.log;
    const settings = context.client.config.settings;

    if (ev.type === 'log') {
      const minLogLevel = getMinLogLevel(settings, ev.source);
      const logLevel = getLogLevel(ev.data['@level']);

      if (logLevel >= 0 && (logLevel > 5 || logLevel < minLogLevel)) {
        log.info('Cancelling log event due to minimum log level.');
        context.cancelled = true;
      }
    } else if (ev.type === 'error') {
      let error: IInnerError = ev.data['@error'];
      while (!context.cancelled && error) {
        if (getTypeAndSourceSetting(settings, ev.type, error.type, true) === false) {
          log.info(`Cancelling error from excluded exception type: ${error.type}`);
          context.cancelled = true;
        }

        error = error.inner;
      }
    } else if (getTypeAndSourceSetting(settings, ev.type, ev.source, true) === false) {
      log.info(`Cancelling event from excluded type: ${ev.type} and source: ${ev.source}`);
      context.cancelled = true;
    }

    next && next();
  }
}

                                                                                                                                                           

export class ModuleInfoPlugin implements IEventPlugin {
  public priority: number = 50;
  public name: string = 'ModuleInfoPlugin';

  public run(context: EventPluginContext, next?: () => void): void {
    const ERROR_KEY: string = '@error'; // optimization for minifier.

    const collector = context.client.config.moduleCollector;
    if (context.event.data[ERROR_KEY] && !context.event.data['@error'].modules && !!collector) {
      const modules: IModule[] = collector.getModules(context);
      if (modules && modules.length > 0) {
        context.event.data[ERROR_KEY].modules = modules;
      }
    }

    next && next();
  }
}

                                                                                                                                                                                                          

export class RequestInfoPlugin implements IEventPlugin {
  public priority: number = 70;
  public name: string = 'RequestInfoPlugin';

  public run(context: EventPluginContext, next?: () => void): void {
    const REQUEST_KEY: string = '@request'; // optimization for minifier.

    const config = context.client.config;
    const collector = config.requestInfoCollector;
    if (!context.event.data[REQUEST_KEY] && !!collector) {
      const requestInfo: IRequestInfo = collector.getRequestInfo(context);
      if (!!requestInfo) {
        if (Utils.isMatch(requestInfo.user_agent, config.userAgentBotPatterns)) {
          context.log.info('Cancelling event as the request user agent matches a known bot pattern');
          context.cancelled = true;
        } else {
          context.event.data[REQUEST_KEY] = requestInfo;
        }
      }
    }

    next && next();
  }
}

                                                                                                           

export class SubmissionMethodPlugin implements IEventPlugin {
  public priority: number = 100;
  public name: string = 'SubmissionMethodPlugin';

  public run(context: EventPluginContext, next?: () => void): void {
    const submissionMethod: string = context.contextData.getSubmissionMethod();
    if (!!submissionMethod) {
      context.event.data['@submission_method'] = submissionMethod;
    }

    next && next();
  }
}

                                                                                     

export class InMemoryStorage implements IStorage {
  private maxItems: number;
  private items: IStorageItem[] = [];
  private lastTimestamp: number = 0;

  constructor(maxItems: number) {
    this.maxItems = maxItems;
  }

  public save(value: any): number {
    if (!value) {
      return null;
    }

    const items = this.items;
    const timestamp = Math.max(Date.now(), this.lastTimestamp + 1);
    const item = { timestamp, value };

    if (items.push(item) > this.maxItems) {
      items.shift();
    }

    this.lastTimestamp = timestamp;
    return item.timestamp;
  }

  public get(limit?: number): IStorageItem[] {
    return this.items.slice(0, limit);
  }

  public remove(timestamp: number): void {
    const items = this.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].timestamp === timestamp) {
        items.splice(i, 1);
        return;
      }
    }
  }

  public clear(): void {
    this.items = [];
  }
}

export interface IClientConfiguration {
  settings: object;
  version: number;
}

                                                                                     

export abstract class KeyValueStorageBase implements IStorage {
  private maxItems: number;
  private items: number[];
  private lastTimestamp: number = 0;

  constructor(maxItems) {
    this.maxItems = maxItems;
  }

  public save(value: any, single?: boolean): number {
    if (!value) {
      return null;
    }

    this.ensureIndex();

    const items = this.items;
    const timestamp = Math.max(Date.now(), this.lastTimestamp + 1);
    const key = this.getKey(timestamp);
    const json = JSON.stringify(value);

    try {
      this.write(key, json);
      this.lastTimestamp = timestamp;
      if (items.push(timestamp) > this.maxItems) {
        this.delete(this.getKey(items.shift()));
      }
    } catch (e) {
      return null;
    }

    return timestamp;
  }

  public get(limit?: number): IStorageItem[] {
    this.ensureIndex();

    return this.items.slice(0, limit)
      .map((timestamp) => {
        // Read and parse item for this timestamp
        const key = this.getKey(timestamp);
        try {
          const json = this.read(key);
          const value = JSON.parse(json, parseDate);
          return { timestamp, value };
        } catch (error) {
          // Something went wrong - try to delete the cause.
          this.safeDelete(key);
          return null;
        }
      })
      .filter((item) => item != null);
  }

  public remove(timestamp: number): void {
    this.ensureIndex();

    const items = this.items;
    const index = items.indexOf(timestamp);
    if (index >= 0) {
      const key = this.getKey(timestamp);
      this.safeDelete(key);
      items.splice(index, 1);
    }
  }

  public clear(): void {
    this.items.forEach((item) => this.safeDelete(this.getKey(item)));
    this.items = [];
  }

  protected abstract write(key: string, value: string): void;
  protected abstract read(key: string): string;
  protected abstract readAllKeys(): string[];
  protected abstract delete(key: string);
  protected abstract getKey(timestamp: number): string;
  protected abstract getTimestamp(key: string): number;

  private ensureIndex() {
    if (!this.items) {
      this.items = this.createIndex();
      this.lastTimestamp = Math.max(0, ...this.items) + 1;
    }
  }

  private safeDelete(key: string): void {
    try {
      this.delete(key);
    } catch (error) {
    }
  }

  private createIndex() {
    try {
      const keys = this.readAllKeys();
      return keys.map((key) => {
        try {
          const timestamp = this.getTimestamp(key);
          if (!timestamp) {
            this.safeDelete(key);
            return null;
          }
          return timestamp;
        } catch (error) {
          this.safeDelete(key);
          return null;
        }
      }).filter((timestamp) => timestamp != null)
        .sort((a, b) => a - b);
    } catch (error) {
      return [];
    }
  }
}

function parseDate(key, value) {
  const dateRegx = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/g;
  if (typeof value === 'string') {
    const a = dateRegx.exec(value);
    if (a) {
      return new Date(value);
    }
  }
  return value;
}

                                                            

export class BrowserStorage extends KeyValueStorageBase {
  private prefix: string;

  public static isAvailable(): boolean {
    try {
      const storage = window.localStorage;
      const x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return false;
    }
  }

  constructor(namespace: string, prefix: string = 'com.exceptionless.', maxItems: number = 20) {
    super(maxItems);

    this.prefix = prefix + namespace + '-';
  }

  public write(key: string, value: string) {
    window.localStorage.setItem(key, value);
  }

  public read(key: string) {
    return window.localStorage.getItem(key);
  }

  public readAllKeys() {
    return Object.keys(window.localStorage)
      .filter((key) => key.indexOf(this.prefix) === 0);
  }

  public delete(key: string) {
    window.localStorage.removeItem(key);
  }

  public getKey(timestamp) {
    return this.prefix + timestamp;
  }

  public getTimestamp(key) {
    return parseInt(key.substr(this.prefix.length), 10);
  }
}

                                                                                                                                                                                                                                                                                                           

export class DefaultErrorParser implements IErrorParser {
  public parse(context: EventPluginContext, exception: Error): IError {
    function getParameters(parameters: string | string[]): IParameter[] {
      const params: string[] = (typeof parameters === 'string' ? [parameters] : parameters) || [];

      const result: IParameter[] = [];
      for (const param of params) {
        result.push({ name: param });
      }

      return result;
    }

    function getStackFrames(stackFrames: TraceKit.StackFrame[]): IStackFrame[] {
      const ANONYMOUS: string = '<anonymous>';
      const frames: IStackFrame[] = [];

      for (const frame of stackFrames) {
        frames.push({
          name: (frame.func || ANONYMOUS).replace('?', ANONYMOUS),
          parameters: getParameters(frame.args),
          file_name: frame.url,
          line_number: frame.line || 0,
          column: frame.column || 0
        });
      }

      return frames;
    }

    const TRACEKIT_STACK_TRACE_KEY: string = '@@_TraceKit.StackTrace'; // optimization for minifier.

    const stackTrace: TraceKit.StackTrace = !!context.contextData[TRACEKIT_STACK_TRACE_KEY]
      ? context.contextData[TRACEKIT_STACK_TRACE_KEY]
      : TraceKit.computeStackTrace(exception, 25);

    if (!stackTrace) {
      throw new Error('Unable to parse the exceptions stack trace.');
    }

    const message = typeof (exception) === 'string' ? exception as any : undefined;
    return {
      type: stackTrace.name || 'Error',
      message: stackTrace.message || exception.message || message,
      stack_trace: getStackFrames(stackTrace.stack || [])
    };
  }
}

                                                                                                                                                                                                         

export class DefaultModuleCollector implements IModuleCollector {
  public getModules(context: EventPluginContext): IModule[] {
    if (!document || !document.getElementsByTagName) {
      return null;
    }

    const modules: IModule[] = [];
    const scripts: NodeListOf<HTMLScriptElement> = document.getElementsByTagName('script');
    if (scripts && scripts.length > 0) {
      for (let index = 0; index < scripts.length; index++) {
        if (scripts[index].src) {
          modules.push({
            module_id: index,
            name: scripts[index].src.split('?')[0],
            version: Utils.parseVersion(scripts[index].src)
          });
        } else if (!!scripts[index].innerHTML) {
          modules.push({
            module_id: index,
            name: 'Script Tag',
            version: Utils.getHashCode(scripts[index].innerHTML).toString()
          });
        }
      }
    }

    return modules;
  }
}

                                                                                                                                                                                                                             

export class DefaultRequestInfoCollector implements IRequestInfoCollector {
  public getRequestInfo(context: EventPluginContext): IRequestInfo {
    if (!document || !navigator || !location) {
      return null;
    }

    const config = context.client.config;
    const exclusions = config.dataExclusions;
    const requestInfo: IRequestInfo = {
      user_agent: navigator.userAgent,
      is_secure: location.protocol === 'https:',
      host: location.hostname,
      port: location.port && location.port !== '' ? parseInt(location.port, 10) : 80,
      path: location.pathname
      // client_ip_address: 'TODO'
    };

    if (config.includeCookies) {
      requestInfo.cookies = Utils.getCookies(document.cookie, exclusions);
    }

    if (config.includeQueryString) {
      requestInfo.query_string = Utils.parseQueryString(location.search.substring(1), exclusions);
    }

    if (document.referrer && document.referrer !== '') {
      requestInfo.referrer = document.referrer;
    }

    return requestInfo;
  }
}

                                                                                                                                                

export class BrowserStorageProvider implements IStorageProvider {
  public queue: IStorage;
  public settings: IStorage;

  constructor(prefix?: string, maxQueueItems: number = 250) {
    this.queue = new BrowserStorage('q', prefix, maxQueueItems);
    this.settings = new BrowserStorage('settings', prefix, 1);
  }

}

                                                                                                                                                                              

// tslint:disable-next-line:prefer-const
declare var XDomainRequest: { new(); create(); };

export class DefaultSubmissionAdapter implements ISubmissionAdapter {
  public sendRequest(request: SubmissionRequest, callback?: SubmissionCallback, isAppExiting?: boolean) {
    // TODO: Handle sending events when app is exiting with send beacon.
    const TIMEOUT: string = 'timeout';  // optimization for minifier.
    const LOADED: string = 'loaded';  // optimization for minifier.
    const WITH_CREDENTIALS: string = 'withCredentials';  // optimization for minifier.

    let isCompleted: boolean = false;
    let useSetTimeout: boolean = false;
    function complete(mode: string, xhrRequest: XMLHttpRequest) {
      function parseResponseHeaders(headerStr) {
        function trim(value) {
          return value.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
        }

        const headers = {};
        const headerPairs = (headerStr || '').split('\u000d\u000a');
        for (const headerPair of headerPairs) {
          // Can't use split() here because it does the wrong thing
          // if the header value has the string ": " in it.
          const separator = headerPair.indexOf('\u003a\u0020');
          if (separator > 0) {
            headers[trim(headerPair.substring(0, separator).toLowerCase())] = headerPair.substring(separator + 2);
          }
        }

        return headers;
      }

      if (isCompleted) {
        return;
      }

      isCompleted = true;

      let message: string = xhrRequest.statusText;
      const responseText: string = xhrRequest.responseText;
      let status: number = xhrRequest.status;

      if (mode === TIMEOUT || status === 0) {
        message = 'Unable to connect to server.';
        status = 0;
      } else if (mode === LOADED && !status) {
        status = request.method === 'POST' ? 202 : 200;
      } else if (status < 200 || status > 299) {
        const responseBody: any = (xhrRequest as any).responseBody;
        if (!!responseBody && !!responseBody.message) {
          message = responseBody.message;
        } else if (!!responseText && responseText.indexOf('message') !== -1) {
          try {
            message = JSON.parse(responseText).message;
          } catch (e) {
            message = responseText;
          }
        }
      }

      callback && callback(status || 500, message || '', responseText, parseResponseHeaders(xhrRequest.getAllResponseHeaders && xhrRequest.getAllResponseHeaders()));
    }

    function createRequest(userAgent: string, method: string, uri: string): XMLHttpRequest {
      let xmlRequest: any = new XMLHttpRequest();
      if (WITH_CREDENTIALS in xmlRequest) {
        xmlRequest.open(method, uri, true);

        xmlRequest.setRequestHeader('X-Exceptionless-Client', userAgent);
        if (method === 'POST') {
          xmlRequest.setRequestHeader('Content-Type', 'application/json');
        }
      } else if (typeof XDomainRequest !== 'undefined') {
        useSetTimeout = true;
        xmlRequest = new XDomainRequest();
        xmlRequest.open(method, location.protocol === 'http:' ? uri.replace('https:', 'http:') : uri);
      } else {
        xmlRequest = null;
      }

      if (xmlRequest) {
        xmlRequest.timeout = 10000;
      }

      return xmlRequest;
    }

    const url = `${request.url}${(request.url.indexOf('?') === -1 ? '?' : '&')}access_token=${encodeURIComponent(request.apiKey)}`;
    const xhr = createRequest(request.userAgent, request.method || 'POST', url);
    if (!xhr) {
      return (callback && callback(503, 'CORS not supported.'));
    }

    if (WITH_CREDENTIALS in xhr) {
      xhr.onreadystatechange = () => {
        // xhr not ready.
        if (xhr.readyState !== 4) {
          return;
        }

        complete(LOADED, xhr);
      };
    }

    xhr.onprogress = () => { };
    xhr.ontimeout = () => complete(TIMEOUT, xhr);
    xhr.onerror = () => complete('error', xhr);
    xhr.onload = () => complete(LOADED, xhr);

    if (useSetTimeout) {
      setTimeout(() => xhr.send(request.data), 500);
    } else {
      xhr.send(request.data);
    }
  }
}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    

(function init() {
  function getDefaultsSettingsFromScriptTag(): IConfigurationSettings {
    if (!document || !document.getElementsByTagName) {
      return null;
    }

    const scripts = document.getElementsByTagName('script');
    // tslint:disable-next-line:prefer-for-of
    for (let index = 0; index < scripts.length; index++) {
      if (scripts[index].src && scripts[index].src.indexOf('/exceptionless') > -1) {
        return Utils.parseQueryString(scripts[index].src.split('?').pop());
      }
    }
    return null;
  }

  function processUnhandledException(stackTrace: TraceKit.StackTrace, options?: any): void {
    const builder = ExceptionlessClient.default.createUnhandledException(new Error(stackTrace.message || (options || {}).status || 'Script error'), 'onerror');
    builder.pluginContextData['@@_TraceKit.StackTrace'] = stackTrace;
    builder.submit();
  }

  if (typeof document === 'undefined') {
    return;
  }

  /*
   TODO: We currently are unable to parse string exceptions.
   function processJQueryAjaxError(event, xhr, settings, error:string): void {
   let client = ExceptionlessClient.default;
   if (xhr.status === 404) {
   client.submitNotFound(settings.url);
   } else if (xhr.status !== 401) {
   client.createUnhandledException(error, 'JQuery.ajaxError')
   .setSource(settings.url)
   .setProperty('status', xhr.status)
   .setProperty('request', settings.data)
   .setProperty('response', xhr.responseText && xhr.responseText.slice && xhr.responseText.slice(0, 1024))
   .submit();
   }
   }
   */

  Configuration.prototype.useLocalStorage = function() {
    if (BrowserStorage.isAvailable()) {
      this.storage = new BrowserStorageProvider();
      SettingsManager.applySavedServerSettings(this);
      this.changed();
    }
  };

  const defaults = Configuration.defaults;
  const settings = getDefaultsSettingsFromScriptTag();
  if (settings) {
    if (settings.apiKey) {
      defaults.apiKey = settings.apiKey;
    }

    if (settings.serverUrl) {
      defaults.serverUrl = settings.serverUrl;
    }

    if (typeof settings.includePrivateInformation === 'string') {
      defaults.includePrivateInformation = settings.includePrivateInformation === 'false' ? false : true;
    }
  }

  defaults.errorParser = new DefaultErrorParser();
  defaults.moduleCollector = new DefaultModuleCollector();
  defaults.requestInfoCollector = new DefaultRequestInfoCollector();
  defaults.submissionAdapter = new DefaultSubmissionAdapter();

  TraceKit.report.subscribe(processUnhandledException);
  TraceKit.extendToAsynchronousCallbacks();

  // window && window.addEventListener && window.addEventListener('beforeunload', function () {
  //   ExceptionlessClient.default.config.queue.process(true);
  // });

  // if (typeof $ !== 'undefined' && $(document)) {
  //   $(document).ajaxError(processJQueryAjaxError);
  // }

  (Error as any).stackTraceLimit = Infinity;
})();

// tslint:disable-next-line:prefer-const
declare var $;

